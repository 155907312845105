import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { updatePropertyDetails } from "../../../../helpers/services/websiteServices";

const UpdateProperty = ({
  cityId,
  properttyId,
  openModal,
  setOpenModal,
  data,
  setFetchData,
}) => {
  const [propertyData, setPropertyData] = useState({
    meta_title: data?.meta_data?.meta_title || "",
    meta_description: data?.meta_data?.meta_description || "",
    meta_keywords: data?.meta_data?.meta_keywords || "",
    display_title: data?.property_name || "",
    property_subtitle: data?.property_subtitle || "",
    description: data?.description || "",
    property_slug: data?.property_slug || "",
    starting_price: data?.starting_price || "",
    gender_allowed: data?.gender_allowed || "",
    location: data?.location || "",
    google_map_link: data?.google_map_link || "",
    latitude: data?.latitude || 0.0,
    longitude: data?.longitude || null,
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPropertyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const updateData = async () => {
    setLoading(true);
    const updatedData = {
      meta_data: {
        meta_title: propertyData.meta_title,
        meta_description: propertyData.meta_description,
        meta_keywords: propertyData.meta_keywords,
      },
      display_title: propertyData.display_title,
      property_subtitle: propertyData.property_subtitle,
      description: propertyData.description,
      property_slug: propertyData.property_slug,
      starting_price: propertyData.starting_price,
      gender_allowed: propertyData.gender_allowed,
      location: propertyData.location,
      google_map_link: propertyData.google_map_link,
      latitude: propertyData.latitude,
      longitude: propertyData.longitude,
    };

    const response = await updatePropertyDetails(
      cityId,
      properttyId,
      updatedData
    );
    // const response = false
    if (response.status === "success") {
      setFetchData(response.data);
      handleClose();
      toast.success("Data updated successfully");
      setLoading(false);
    } else {
      toast.error("Error updating data, please try again");
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={handleClose}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title className="underline">{data?.display_title}</Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="form_control_box">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <label htmlFor="display_title" className="form-label">
                    <b>Display Title</b>
                  </label>
                  <input
                    type="text"
                    id="display_title"
                    className="form-control mt-1"
                    name="display_title"
                    onChange={handleChange}
                    value={propertyData.display_title}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label htmlFor="property_subtitle" className="form-label">
                    <b>Property Subtitle</b>
                  </label>
                  <input
                    type="text"
                    id="property_subtitle"
                    className="form-control mt-1"
                    name="property_subtitle"
                    onChange={handleChange}
                    value={propertyData?.property_subtitle}
                  />
                </div>
              </div>
            </div>

            <div className="mb-2">
              <label htmlFor="description" className="form-label">
                <b>Description</b>
              </label>
              <textarea
                id="description"
                className="form-control mt-1"
                name="description"
                onChange={handleChange}
                value={propertyData.description}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="property_slug" className="form-label">
                <b>Property Slug</b>
              </label>
              <input
                type="text"
                id="property_slug"
                className="form-control mt-1"
                name="property_slug"
                onChange={handleChange}
                value={propertyData.property_slug}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="starting_price" className="form-label">
                <b>Starting Price</b>
              </label>
              <input
                type="text"
                id="starting_price"
                className="form-control mt-1"
                name="starting_price"
                onChange={handleChange}
                value={propertyData.starting_price}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="gender_allowed" className="form-label">
                <b>Gender Allowed</b>
              </label>
              <input
                type="text"
                id="gender_allowed"
                className="form-control mt-1"
                name="gender_allowed"
                onChange={handleChange}
                value={propertyData.gender_allowed}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="location" className="form-label">
                <b>Location</b>
              </label>
              <input
                type="text"
                id="location"
                className="form-control mt-1"
                name="location"
                onChange={handleChange}
                value={propertyData.location}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="google_map_link" className="form-label">
                <b>Google Map Link</b>
              </label>
              <input
                type="text"
                id="google_map_link"
                className="form-control mt-1"
                name="google_map_link"
                onChange={handleChange}
                value={propertyData.google_map_link}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="latitude" className="form-label">
                <b>Latitude</b>
              </label>
              <input
                type="number"
                id="latitude"
                className="form-control mt-1"
                name="latitude"
                onChange={handleChange}
                value={propertyData.latitude}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="longitude" className="form-label">
                <b>Longitude</b>
              </label>
              <input
                type="number"
                id="longitude"
                className="form-control mt-1"
                name="longitude"
                onChange={handleChange}
                value={propertyData.longitude}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="basic-button"
            onClick={updateData}
            disabled={loading}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateProperty;
